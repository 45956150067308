<template>
  <div data-v-41174c71="" class="content showFooter">
    <div data-v-41174c71="" class="continer">
      <div data-v-4f79f02a="" data-v-41174c71="" class="log-list">
        <!---->
        <div data-v-4f79f02a="" class="title">
          <div data-v-4f79f02a="" class="text">入驻申请记录</div>
          <div data-v-4f79f02a="" class="btn">
            <div data-v-4f79f02a="" class="shopee-popover shopee-popover--light">
              <div class="shopee-popover__ref">
                <!-- <button
                  data-v-4f79f02a=""
                  type="button"
                  disabled="disabled"
                  class="
                    shopee-button shopee-button--primary shopee-button--normal
                    disabled
                  "
                >
                  <span>申请入驻</span>
                </button> -->
                <el-popover placement="top" width="300" trigger="hover" :disabled="!btnJZ"
                  content="此账号已有在审核进程中的申请记录，无法再次申请入驻">
                  <el-button size="small" @click="toApplay" type="primary" slot="reference"
                    :class="{ jinz: btnJZ }">申请入驻</el-button>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
        <div class="header-container">
          <div style="width: 72px">序号</div>
          <div style="width: 280px">公司名称</div>
          <div style="width: 211px">申请创建时间</div>
          <div style="width: 211px">状态更新时间</div>
          <div style="width: 211px">审核状态</div>
          <div style="width: 213px">操作</div>
        </div>
        <div v-if="httpShow">
          <div class="content-container">
            <div class="content-container-item" v-for="(item, index) in logData" :key="index">
              <div style="width: 72px; color: #19253780">{{ item.id }}</div>
              <div style="width: 280px">
                {{
                item.company_info ? item.company_info.company_name : "-----"
                }}
              </div>
              <div style="width: 211px">{{ item.create_time }}</div>
              <div style="width: 211px">
                {{ item.update_time ? item.update_time : "----" }}
              </div>
              <div style="width: 211px">
                <div class="aubit-btn" v-if="item.verify <= 4" @click="dialogVisible1 = true">
                  等待递交资料>
                </div>
                <div class="aubit-btn-in" v-if="item.verify == 5" @click="dialogVisible = true">
                  初审中>
                </div>
                <div class="aubit-btn-success" v-if="item.verify == 6">
                  申请已经通过>
                </div>
                <div class="aubit-btn-error" v-if="item.verify == 7">
                  审核未通过>
                </div>
              </div>
              <div style="width: 213px">
                <span class="edit-btn" v-if="item.verify < 5" @click="editInfo(index)">编辑资料</span>
                <span class="edit-btn" v-if="item.verify == 5 || item.verify == 6" @click="lookInfo(index)">查看详情</span>
                <span class="edit-btn" v-if="item.verify == 6" @click="lookShop(index)">查看店铺</span>
                <span class="edit-btn" v-if="item.verify == 5" @click="openShop(item)">开通店铺</span>
              </div>
            </div>

            <div v-if="logData.length == 0" data-v-69e427c2="" class="empty">
              <div data-v-69e427c2="" class="empty-content">
                <img data-v-69e427c2=""
                  src="https://deo.shopeesz.com/shopee/shopee-registration-live-sg/static/img/empty.9c78f73.jpg" alt=""
                  class="img" />
                <p data-v-69e427c2="" class="desc">
                  您还未创建入驻申请，请尽快提交您的入驻信息。
                </p>
                <div data-v-69e427c2="" class="apply-btn">
                  <button data-v-69e427c2="" type="button" class="
                      shopee-button shopee-button--primary shopee-button--large
                    " @click="toApplay">
                    <span>立即申请入驻</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 100%; text-align: center" v-else>
          <img :src="httpImg" alt="" />
        </div>
      </div>
    </div>
    <el-dialog title="审核状态" :visible.sync="dialogVisible" width="640px">
      <div style="display: flex">
        <div>
          <div style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 5%;
            ">
            <div class="small-y"></div>
            <div class="line-y" style="height: 40px"></div>
          </div>
          <div style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 5%;
            ">
            <div class="big-y"></div>
            <div class="line-s" style="height: 180px"></div>
          </div>
          <div style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 5%;
            ">
            <div class="big-s"></div>
          </div>
        </div>
        <div>
          <div style="color: #ee4d2d">等待递交资料</div>
          <div style="
              margin-top: 30px;
              background: #ee4d2d;
              width: 60px;
              text-align: center;
              color: #fff;
              padding: 2px 0;
            ">
            初审中
          </div>
          <div style="margin-top: 20px">
            您已成功提交资料，请等待Shopee审核!
            <br />
            <span style="color: #ee4d2d">
              预计审核时效是5个工作日，若遇节假日和周末，审核时效会相应顺延， </span>审核部门的审核时间为每周一到周五的9点30-18点30分，超过当天18点以后递交算为次日递交，周五18点以后递交算为周一递交。
            <br />
            审核进度会显示在shopee.cn，请您及时登录查看，或留意您的联系人邮箱。
            <br />
            为避免延长您的审核，收到结果后如需补充材料请及时递交。
          </div>
          <div style="margin-top: 36px; color: #999999">申请已经通过</div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="审核状态" :visible.sync="dialogVisible1" width="640px">
      <div style="display: flex">
        <div>
          <div style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 5%;
            ">
            <div class="big-y"></div>
            <div class="line-s" style="height: 94px"></div>
          </div>
          <div style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 5%;
            ">
            <div class="big-s"></div>
            <div class="line-s" style="height: 46px"></div>
          </div>
          <div style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 5%;
            ">
            <div class="big-s"></div>
          </div>
        </div>
        <div>
          <div style="color: #ee4d2d">
            <span style="background: #ee4d2d; color: #fff; padding: 2px 10px">等待递交资料</span>
          </div>
          <div style="margin-top: 20px">
            您已开始提交资料，请您尽快完成提交。
            <br />
            若长时间未提交，不仅会影响您后续的审核进程而且会导致您的申请被关闭。
          </div>
          <div style="
              margin-top: 30px;
           color: #999999
              width: 60px;
     
            ">
            初审中
          </div>

          <div style="margin-top: 36px; color: #999999">申请已经通过</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { inList } from "@/api/main.js";
  export default {
    data() {
      return {
        logData: [],
        btnJZ: false,
        reverse: true,
        dialogVisible: false,
        dialogVisible1: false,
        httpShow: false,
        httpImg: require("../../../assets/img/app/http.gif"),
      };
    },
    created() {
      inList().then((res) => {
        if (res.code == 1) {
          localStorage.MAINID = res.data.id;
          this.httpShow = true;
          this.logData = res.data.list;
          // this.logData[0].verify = 1;
          this.logData.forEach((item) => {
            if (item.verify <= 5) {
              this.btnJZ = true;
            }
          });
        }
        if (res.code == -1 || res.code == 6000) {
          if (sessionStorage.BACKURL) {
            // window.location.replace(sessionStorage.BACKURL)
          }
        }
      });
    },
    methods: {
      toApplay() {
        if (!this.btnJZ) {
          this.$router.push("inProcess");

          localStorage.ININDEX = "";
        }
      },
      editInfo(index) {
        localStorage.ININDEX = index;
        this.$router.push("inProcess");
      },
      lookInfo(index) {
        localStorage.ININDEX = index;
        this.$router.push("inDetails");
      },
      lookShop(index) {
        localStorage.ININDEX = index;
        this.$router.push("storeList");
      },
      openShop(val) {
        this.$router.push("open");
        sessionStorage.MAIN_PHONE = val.contact_mobile;
      },
    },
  };
</script>
<style lang="less" scoped>
  @import "../../../style/Inrecord/11.css";
  @import "../../../style/Inrecord/18.css";
  @import "../../../style/Inrecord/shopee-ui-collect-message.css";
  @import "../../../style/Inrecord/shopee-ui-collect.css";

  .header-container {
    display: flex;
    font-size: 14px;
    color: #19253780;
    justify-content: space-between;
    padding: 10px 0;
  }

  .big-y {
    width: 18px;
    height: 18px;
    border: 4px solid #fff;
    box-shadow: 0 0 1px #ee4d2d;
    border-radius: 50%;
    background: #ee4d2d;
  }

  .small-y {
    width: 10px;
    height: 10px;
    background: #ee4d2d;
    border-radius: 50%;
  }

  .big-s {
    width: 10px;
    height: 10px;
    background: #d8d8d8;
    border-radius: 50%;
  }

  .line-y {
    width: 2px;
    background: #ee4d2d;
  }

  .line-s {
    width: 2px;
    background: #d8d8d8;
  }

  .content-container {
    font-size: 14px;

    .content-container-item {
      display: flex;
      justify-content: space-between;
      height: 62px;
      padding: 16px 0;
      border-bottom: 1px solid #eee;

      .aubit-btn {
        width: 106px;
        background: rgba(242, 123, 0, 0.1);
        color: #f27b00;
        padding: 3px 5px;
      }

      .aubit-btn:hover {
        background: rgba(242, 123, 0, 0.2);
        cursor: pointer;
      }

      .aubit-btn-in {
        width: 66px;
        background: rgba(235, 236, 238, 0.5);
        color: #404b5b;
        padding: 3px 5px;
      }

      .aubit-btn-in:hover {
        background: rgba(235, 236, 238, 1);
        cursor: pointer;
      }

      .aubit-btn-success {
        width: 106px;
        background: rgba(229, 246, 244, 0.5);
        color: #00a597;
        padding: 3px 5px;
      }

      .aubit-btn-success:hover {
        background: rgba(229, 246, 244, 0.8);
        cursor: pointer;
      }

      .aubit-btn-error {
        width: 96px;
        background: rgba(220, 20, 60, 0.8);
        color: #fff;
        padding: 3px 5px;
      }

      .aubit-btn-error:hover {
        background: rgba(220, 20, 60, 1);
        cursor: pointer;
      }

      .edit-btn {
        color: #0079ff;
        margin-right: 16px;
      }

      .edit-btn:hover {
        color: #004ea5;
        cursor: pointer;
      }
    }
  }

  .jinz {
    color: #fff;
    background-color: #f7a696;
    border-color: #f7a696;
  }

  .jinz:hover {
    cursor: not-allowed;
  }
</style>